import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "../assets/css/home-slider.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import img1 from "../assets/img/slider_01.webp";
import img2 from "../assets/img/slider_02.webp";
import img3 from "../assets/img/slider_03.webp";
import img4 from "../assets/img/slider_04.webp";
import img5 from "../assets/img/slider_05.webp";
import img6 from "../assets/img/slider_06.webp";
import { useSelector } from "react-redux";

const HomeSlider = () => {
  // Language & data from Redux
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);

  // Determine device type and orientation based on screen width and height
  const getDeviceType = () => {
    const width = window.innerWidth;
    if (width <= 430) return "phone";
    else if (width <= 1366) return "tablet";
    else return "desktop";
  };

  const [deviceType, setDeviceType] = useState(getDeviceType());

  // Update device type on window resize
  useEffect(() => {
    const handleResize = () => setDeviceType(getDeviceType());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Slider content data - centralized configuration
  const sliderData = [
    {
      id: 1,
      image: img1,
      altText: "Slide 1",
      customClass: "",
      link: `/${language}/urunler`,
      showButton: true,
    },
    {
      id: 2,
      image: img2,
      altText: "Slide 2",
      customClass: "",
      link:
        language === "en"
          ? "/en/urunler/?kategori=Gift-Set"
          : "/tr/urunler/?kategori=Hediye-Seti",
      showButton: true,
    },
    {
      id: 3,
      image: img3,
      altText: "Slide 3",
      customClass: "",
      link: `/${language}/urunler`,
      showButton: true,
    },
    {
      id: 4,
      image: img4,
      altText: "Slide 4",
      customClass: "",
      link: `/${language}/urunler`,
      showButton: true,
    },
    {
      id: 5,
      image: img5,
      altText: "Slide 5",
      customClass: "",
      link: `/${language}/urunler`,
      showButton: false,
    },
    {
      id: 6,
      image: img6,
      altText: "Slide 6",
      customClass: "",
      link: `/${language}/urunler`,
      showButton: true,
    },
  ];

  // Button rendering function to reduce repetition
  const renderButtons = (slide) => {
    if (!slide.showButton) return null;

    return (
      <>
        {/* Desktop Button */}
        {deviceType === "desktop" && (
          <div className="desktop-button-container">
            <a href={slide.link}>
              <button className="button-type-six" id={`kesfet_${slide.id}_btn`}>
                {data.kesfet}
              </button>
            </a>
          </div>
        )}

        {/* Mobile/Tablet Button */}
        {deviceType !== "desktop" && (
          <div className="mobile-button-container">
            <a href={slide.link}>
              <button
                className="mobile-button-type"
                id={`mobile_kesfet_${slide.id}_btn`}
              >
                {data.kesfet}
              </button>
            </a>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="home-slider-main">
      <div className="col-12">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          loop={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          speed={800}
          className="mySwiper"
          onSlideChange={() => {
            const mobileButtons = document.querySelectorAll(
              ".mobile-button-type"
            );
            mobileButtons.forEach((button) => {
              button.classList.remove("animate-button");
              setTimeout(() => {
                button.classList.add("animate-button");
              }, 50);
            });
          }}
        >
          {sliderData.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className="slide-container">
                <img src={slide.image} alt={slide.altText} />
                <span className="slide-text">{renderButtons(slide)}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeSlider;

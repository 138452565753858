import React, { useState } from "react";
import config from "../config";
import { addToCart } from "../redux/actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import useStorageListener from "../utils/useStorageListener";

const { REACT_APP_BASE_URL } = process.env;

const AddBasketButton = ({ isLoggedIn, cartItems, product }) => {
  useStorageListener();

  const [counter, setCounter] = useState(1);
  const userDetails = useSelector((state) => state.account.userDetails);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const { data } = useSelector((state) => state.language);
  const language = useSelector((state) => state.language.language);

  const addBasket = async (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      const updatedItem = {
        product_name:
          language == "en" ? product.product_name_en : product.product_name,
        product_id: product.product_id,
        product_price: product.comb_prices[0].price,
        product_category_name:
          language == "en"
            ? product.categories[0].category_name_en
            : product.categories[0].category_name,
        product_image_url: product.product_images[0].image_url,
        comb_att_id: product.comb_prices[0].comb_atts[0].comb_att_id,
        quantity: counter,
        count: counter,
        product_image_attributes:
          language == "en"
            ? product.product_images[0].image_attribute_en
            : product.product_images[0].image_attribute,
      };

      dispatch(addToCart(updatedItem));
      toast.success(data.urun_sepete_eklendi);
      return;
    }

    try {
      const findCartResponse = await axios.post(
        REACT_APP_BASE_URL + "cart/find",
        {
          user_id: userDetails.user_id,
        },
        {
          headers: {
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
            "Cache-Control": "no-store", // Cache'i devre dışı bırak (sepet backendden güncel olsun hep diye)
          },
        }
      );
      const existingCarts = findCartResponse.data.cart;

      if (existingCarts) {
        const existingCart = existingCarts.carts[0];
        const combinedCombAtts = [
          ...existingCart.comb_atts,
          ...product.comb_prices[0].comb_atts.map((item) => ({
            ...item,
            quantity: 1,
          })),
        ];

        const results = cartItems.filter(
          (items) =>
            product.comb_prices[0].comb_atts[0].comb_att_id ===
            items.comb_att_id
        );

        let updateCartResponse = "";
        if (
          results.length > 0 &&
          product.comb_prices[0].comb_atts[0].comb_att_id.length > 0
        ) {
          updateCartResponse = await axios.post(
            REACT_APP_BASE_URL + "cart/update",
            {
              cart_id: existingCart.cart_id,
              comb_atts: [
                {
                  comb_att_id: product.comb_prices[0].comb_atts[0].comb_att_id,
                  quantity: results[0].count + 1,
                },
              ],
            },
            {
              headers: {
                xsfr: config.secretKey,
                Authorization: `Bearer ${token.tokenAuth}`,
              },
            }
          );
        } else {
          updateCartResponse = await axios.post(
            REACT_APP_BASE_URL + "cart/update",
            {
              cart_id: existingCart.cart_id,
              comb_atts: combinedCombAtts,
            },
            {
              headers: {
                xsfr: config.secretKey,
                Authorization: `Bearer ${token.tokenAuth}`,
              },
            }
          );
        }

        if (
          updateCartResponse.data.message ===
          "Cart details updated successfully"
        ) {
          const priceResult = product.comb_prices[0];
          const updatedItem = {
            cart_id: existingCart.cart_id,
            comb_att_id: priceResult.comb_atts[0].comb_att_id,
            product_name:
              language == "en" ? product.product_name_en : product.product_name,
            product_id: product.product_id,
            product_price: priceResult.price,
            product_category_name:
              language == "en"
                ? product.categories[0].category_name_en
                : product.categories[0].category_name,
            product_image_url: product.product_images[0].image_url,
            count: counter,
            product_image_attributes:
              language == "en"
                ? product.product_images[0].image_attribute_en
                : product.product_images[0].image_attribute,
          };
          localStorage.setItem("taskCompleted", Date.now());

          dispatch(addToCart(updatedItem, updateCartResponse.data.arrs));
          toast.success(data.urun_sepete_guncellendi);
        } else {
          toast.error(data.sepete_guncellerken_hata);
        }
      } else {
        const comb_atts_arry = [
          {
            comb_att_id: product.comb_prices[0].comb_atts[0].comb_att_id,
            quantity: 1,
          },
        ];
        const addCartResponse = await axios.post(
          REACT_APP_BASE_URL + "cart/add",
          {
            user_id: userDetails.user_id,
            comb_atts: comb_atts_arry,
          },
          {
            headers: {
              xsfr: config.secretKey,
              Authorization: `Bearer ${token.tokenAuth}`,
            },
          }
        );

        if (addCartResponse.data.message === "Sepet başarıyla oluşturuldu.") {
          const updatedItem = {
            cart_id: addCartResponse.data.cart.cart_id,
            comb_att_id: addCartResponse.data.cartDetails[0].comb_att_id,
            product_name:
              language == "en" ? product.product_name_en : product.product_name,
            product_id: product.product_id,
            product_price: product.comb_prices[0].price,
            product_category_name:
              language == "en"
                ? product.categories[0].category_name_en
                : product.categories[0].category_name,
            product_image_url: product.product_images[0].image_url,
            count: counter,
            product_image_attributes:
              language == "en"
                ? product.product_images[0].image_attribute_en
                : product.product_images[0].image_attribute,
          };
          localStorage.setItem("taskCompleted", Date.now());

          dispatch(addToCart(updatedItem));
          toast.success(data.urun_sepete_eklendi);
        } else {
          toast.error(data.sepete_eklerken_hata);
        }
      }
    } catch (error) {
      toast.error(data.sepete_eklerken_hata);
    }
  };

  return (
    <button onClick={(e) => addBasket(e)} className="button-type-two w-100">
      {data.sepete_ekle}
    </button>
  );
};

AddBasketButton.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    cartItems: state.cart.cartItems,
  };
};

export default connect(mapStateToProps)(AddBasketButton);
